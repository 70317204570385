<template>
  <div>
    <div class="d-md-flex mb-3">
      <div :class="isMobile ? 'w-100' : 'mr-3'">
        <a-radio-group :class="isMobile ? 'w-100' : null" @change="handleChangeType" size="large" v-model="type" button-style="solid">
          <a-radio-button value="mid">
            MID
          </a-radio-button>
          <a-radio-button value="final">
            FINAL
          </a-radio-button>
        </a-radio-group>
      </div>
      <div class="ml-auto">
        <a-button
          :class="isMobile ? ['w-100', 'my-3'] : null"
          size="large"
          @click.prevent="refreshTable"
        >
          <a-icon type="sync" :spin="loadingRefresh" />
          Refresh
        </a-button>
      </div>
    </div>
    <a-table
      class="hasan-table-overflow"
      :columns="columns"
      :data-source="data"
      :pagination="false"
      :loading="loadingTable"
      bordered
    >
    <div slot="walikelas" slot-scope="text, record">
      <router-link
        v-if="text"
        :to="{ name: 'Reports Homeroom Teacher', query: { id_walikelas: record.id_guru, isCurriculum: true, } }"
        >{{ text }}</router-link
      >
      <div v-else>-</div>
    </div>
    <div slot="percentage" slot-scope="value">
      <a-progress :percent="value" status="active" />
    </div>
    <div slot="action" slot-scope="value, record">
      <a-button @click.prevent="toReportDetail(record.key)" class="text-primary border-primary" icon="book">Report Details</a-button>
    </div>
  </a-table>
  </div>
</template>

<script>
const columns = [
  {
    title: 'Class',
    dataIndex: 'kelas',
    key: 'kelas',
    align: 'center',
    width: 150,
  },
  {
    title: 'Homeroom Teacher',
    dataIndex: 'walikelas',
    key: 'walikelas',
    scopedSlots: { customRender: 'walikelas' },
  },
  {
    title: 'Progress',
    children: [
      {
        title: 'Spiritual',
        dataIndex: 'spiritual',
        key: 'spiritual',
        align: 'center',
        width: 200,
        scopedSlots: { customRender: 'percentage' },
      },
      {
        title: 'Social',
        dataIndex: 'social',
        key: 'social',
        align: 'center',
        width: 200,
        scopedSlots: { customRender: 'percentage' },
      },
    ],
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    align: 'center',
    scopedSlots: { customRender: 'action' },
  },
]
export default {
  data() {
    return {
      loadingTable: false,
      loadingRefresh: false,
      type: 'mid',
      columns,
      data: [],
      idKelas: this.$route.params.id,
    }
  },
  methods: {
    toReportDetail(idKelas) {
      console.log(idKelas)
      this.$router.push({ name: 'Homeroom Teacher Progress Teacher', query: { type: this.type }, params: { id_kelas: idKelas } })
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Currriculum Teacher'],
      })
    },
    handleChangeType() {
      this.fetchDataTable(this.type)
    },
    refreshTable() {
      this.loadingRefresh = true
      this.fetchDataTable(this.type)
    },
    async fetchDataTable(type) {
      try {
        this.loadingTable = true
        const res = await this.$store.dispatch('curriculum/FETCH_HOMEROOM_TEACHER_PROGRESS', { type })
        this.loadingRefresh = false
        this.loadingTable = false
        // console.log(res)
        this.data = res.map(row => {
          return {
            key: row.kelas.id,
            id_guru: row.wali_kelas?.id,
            kelas: `${row.kelas.tingkat}-${row.kelas.simbol}`,
            walikelas: row.wali_kelas?.nama,
            spiritual: Number(row.progress.spiritual) * 100,
            social: Number(row.progress.social) * 100,
          }
        })
        return new Promise((resolve, reject) => resolve())
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => reject(err))
      }
    },
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
  mounted() {
    this.fetchDataTable(this.type)
  },
}
</script>

<style>

</style>
